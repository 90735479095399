import {css, html, LitElement, nothing, unsafeCSS} from "lit";
import {styleMap} from "lit/directives/style-map.js";
import warningIconImage from '../images/routing-warning-icon.svg';
import close_icon from "../images/warning-close-icon.svg";

const gettext = window.gettext,
    pgettext = window.pgettext; // We can swap this out for a lit directive later if necessary
// Set up WARNING as the closest thing JS can have to an enum... frozen object wrapped around unique symbols.
export const WARNING = {
    FOG: Symbol("WARNING.FOG"),
    FOG_AND_RIME: Symbol("WARNING.FOG_AND_RIME"),
    GUST: Symbol("WARNING.GUST"),
    CHILL: Symbol("WARNING.CHILL"),
    SQUALL: Symbol("WARNING.SQUALL"),
    THUNDERSTORM: Symbol("WARNING.THUNDERSTORM"),
    LIGHTNING: Symbol("WARNING.LIGHTNING"),
    ROLL: Symbol("WARNING.ROLL"),
    VACC: Symbol("WARNING.VACC"),
    SLAM: Symbol("WARNING.SLAM"),
    WAC: Symbol("WARNING.WAC")
};
Object.freeze(WARNING);

export const WARNING_TEXT = {
        [WARNING.FOG]: pgettext('warning', "Fog"),
        [WARNING.FOG_AND_RIME]: pgettext('warning', "Fog + Rime"),
        [WARNING.GUST]: pgettext('warning', "Gust"),
        [WARNING.CHILL]: pgettext('warning', "Wind Chill"),
        [WARNING.SQUALL]: pgettext('warning', "Rain Squall"),
        [WARNING.THUNDERSTORM]: pgettext('warning', "Thunderstorm"),
        [WARNING.LIGHTNING]: pgettext('warning', "Lightning"),
        [WARNING.ROLL]: pgettext('warning', "Roll"),
        [WARNING.VACC]: pgettext('warning', "Vertical Acc"),
        [WARNING.SLAM]: pgettext('warning', "Slamming Inc"),
        [WARNING.WAC]: pgettext('warning', 'Wind against Current')
    },
    close_text = gettext("close");


export const
    warning_title = gettext("Warning"),
    warningOrange = '#FF7A00',
    warningOrangeCSS = unsafeCSS(warningOrange);

// language=css
export const warningStyles = css`
    .cell-warning-container {
        width: 28px;
        text-align: initial;
    }

    .cell-warning {
        color: White;
        background-color: ${warningOrangeCSS};
        font-weight: 700;
        white-space: nowrap;
        position: absolute;
        z-index: 2;
    }

    .warning-title {
        font-size: inherit;
        text-transform: uppercase;
        font-weight: 400;
    }
`;
// language=css
export const wideWarningStyles = css`
    .cell-warning-container {
        min-height: 28px;
        position: relative;
        font-size: 11px;
    }

    .warning-icon {
        position: absolute;
    }

    .cell-warning {
        padding: 3px 4px;
        line-height: 12px;
        border-radius: 3px;
        left: 14px;
        top: 30px;
        transform: translate(-50%, 0);
    }

    .warning-close-icon {
        display: none;
    }
`;
// language=css
export const narrowWarningStyles = css`
    .cell-warning-container {
        position: relative;
    }

    .cell-warning {
        right: 0;
        top: 50%;
        font-size: 14px;
        line-height: 17px;
        transform: translate(0, -50%);
        padding: 12px 8px 12px 13px;
        border-radius: 14px;
    }

    :host(.summary) .cell-warning {
        right: auto;
        transform: translate(-50%, -50%);
    }

    .warning-close-icon {
        display: inline-block;
        width: 22px;
        height: 22px;
        cursor: pointer;
        margin-left: 17px;
    }
`;


function warningTypeClick(w) {
    return event => {
        let $control;
        switch (w) {
            case WARNING.GUST:
            case WARNING.CHILL:
                $control = $j('#tab-routing-wind');
                break;
            case WARNING.ROLL:
            case WARNING.SLAM:
            case WARNING.VACC:
                $control = $j('#tab-routing-wave');
                break;
            case WARNING.FOG:
            case WARNING.FOG_AND_RIME:
            case WARNING.SQUALL:
            case WARNING.THUNDERSTORM:
            case WARNING.LIGHTNING:
                $control = $j('#tab-routing-atmosphere');
                break;
            case WARNING.WAC:
                $control = $j('#tab-routing-current');
                break;
            default:
                $control = $j();
                console.log("Where do we go for this warning? Don't know, sorry!");
                break;
        }
        if (!$control.is(':checked')) {
            $control.trigger('click');
        }
    }
}


export class PWRoutingTableWarning extends LitElement {
    static get properties() {
        return {
            "warnings": {type: Array},
            "open": {type: Boolean}
        };
    }

    constructor() {
        super();
        this.warnings = [];
        this.open = false;
        this._toggleEventHandler = event => {
            const realTarget = event.composedPath()[0]
            this.open = realTarget === this ? !this.open : false;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('warning-toggle', this._toggleEventHandler);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('warning-toggle', this._toggleEventHandler);
    }

    toggle(event) {
        event.preventDefault();
        this.dispatchEvent(new CustomEvent('warning-toggle', {
            bubbles: true,
            composed: true
        }));
    }

    render() {
        const x = new Set(this.warnings);
        // FIXME refactor to work with the array instead?
        if (!x || !x.size) {
            return nothing;
        }
        const w = [...x][0]; // The only warning if the number of warnings is 1.

        const close_img = html`
          <img alt=${close_text} class="warning-close-icon"
               src="${close_icon}"
               style="${styleMap(x.size > 1 ? {float: "right"} : {verticalAlign: "middle"})}">
        `;

        return html`
          <div class="cell-warning-container" @click=${this.toggle}>
            <img class="warning-icon" alt="WARNINGS" src="${warningIconImage}" style="vertical-align: top">
            <div ?hidden="${!this.open}" class="cell-warning">
              ${x.size > 1 ? html`
                <span class="warning-title">${warning_title}</span>
                <div style="min-width: 190px">
                  ${close_img}
                  ${[...x].map(w =>
                          html`
                            <span style="white-space: nowrap; cursor: pointer;"
                                  @click="${warningTypeClick(w)}">${WARNING_TEXT[w]}</span>
                            <br>`
                  )}
                </div>
              ` : html`
                <span class="warning-title" @click=${warningTypeClick(w)}>${warning_title}</span>
                <span style="white-space: nowrap; cursor: pointer;" @click="${warningTypeClick(w)}">${WARNING_TEXT[w]}</span>
                ${close_img}
              `}
            </div>
          </div>
        `;
    }

    static get styles() {
        // language=css
        return css`
            :host {
                display: block;
            }

            img {
                -webkit-touch-callout: none; /* Don't ever show a context menu for an image. How to apply this to all shadow DOMs? */
            }

            ${warningStyles}

            /*  Intentionally allowing narrow layout on desktop.
                Should use container media query when available or polyfill added. */
            @media only screen
            and (min-width: 320px)
            and (max-width: 736px) /* Should we change MAX_PHONE_LAYOUT_WIDTH to be 736px? GHB */
            and (orientation: portrait) {
                ${narrowWarningStyles}
            }

            /* Inverse media query */
            @media not screen
            and (min-width: 320px)
            and (max-width: 736px)
            and (orientation: portrait) {
                ${wideWarningStyles}
            }
        `;
    }
}