import {css, html, LitElement, nothing} from "lit";
import {isSmartPhone} from "../../platform.js";
import {isset, ROUTING_INVALID_VALUE, shouldShowFuelConsumption} from "../routing-utils.js";
import {getUnitSymbol} from '../convertable-units.js';
import {getUserNode} from '../routing-units.js';
import {preference_units} from '../preference-units.js';

const
    gettext = window.gettext,
    interpolate = window.interpolate;

function interpolateUnit(fmt, unit) {
    return interpolate(fmt, { unit:getUnitSymbol(unit) }, true);
}
const
    text_CAPE = gettext("CAPE"),
    text_CAPE_unit = gettext("CAPE (%(unit)s)"),
    text_current_direction = gettext("Current Direction"),
    text_current_direction_unit = gettext("Current Direction (%(unit)s)"),
    text_current_speed = gettext("Current Speed"),
    text_current_speed_unit = gettext("Current Speed (%(unit)s)"),
    text_direction_angle = gettext("Direction (Angle)"),
    text_direction_unit = gettext("Direction (%(unit)s)"),
    text_height_unit = gettext("Height (%(unit)s)"),
    text_influence_of_waves = gettext("Influence of waves on polar"),
    // Translators: xgettext:no-c-format
    text_influence_of_waves_percent = gettext("Influence of waves on polar (%)"),
    text_know_with_current_direction_data = gettext("Know what’s happening beneath the waves and how to best approach areas of current, with Current Direction data "),
    text_know_with_current_speed_data = gettext("Know what’s happening beneath the waves and how to best approach areas of current, with Current Speed data "),
    text_period_unit = gettext("Period (%(unit)s)"),
    text_pressure = gettext("Pressure"),
    text_pressure_unit = gettext("Pressure (%(unit)s)"),
    text_primary_swell_professional = gettext("Primary Swell is only available with a Professional subscription"),
    text_rain = gettext("Rain"),
    text_rain_unit = gettext("Rain (%(unit)s)"),
    text_roll = gettext("Roll"),
    text_roll_degrees = gettext("Roll (Degrees)"),
    text_roll_rms_degrees = gettext("Roll (RMS Degrees)"),
    text_roll_subtitle = gettext("At 4+ deg Roll, movement around boat is difficult and unsafe"),
    text_roll_upgrade = gettext("Avoid rough conditions that can cause sea sickness, with Roll data"),
    text_secondary_swell_professional = gettext("Secondary Swell is only available with a Professional subscription"),
    // Translators: xgettext:no-c-format
    text_slaminc_percent = gettext("Slaminc (%)"),
    // Translators: xgettext:no-c-format
    text_slaminc_subtitle = gettext("50% Slamming Inc or greater may lead to hull damage or crew injuries"),
    text_slaminc_upgrade = gettext("Avoid dangerous impacts that can damage your boat and injure your crew, with Slamming Incidence data"),
    // Translators: xgettext:no-c-format
    text_slamming_inc_percent = gettext("Slamming Inc. (%)"),
    text_slamming_incidence = gettext("Slamming Incidence"),
    text_speed_unit = gettext("Speed (%(unit)s)"),
    // Translators: xgettext:no-c-format
    text_fuel_consumption_label = gettext("Fuel Consumption"),
    text_fuel_consumption_unit = gettext("Fuel Consumption (%(unit)s)"),
    text_temperature = gettext("Temperature"),
    text_temperature_unit = gettext("Temperature (%(unit)s)"),
    text_tertiary_swell_professional = gettext("Tertiary Swell is only available with a Professional subscription"),
    text_twa = gettext("True Wind Angle"),
    text_twd = gettext("True Wind Direction"),
    text_tws = gettext("True Wind Speed"),
    text_tws_unit = gettext("True Wind Speed (%(unit)s)"),
    text_vertacc = gettext("Vertacc (g)"),
    text_vertacc_rms_g = gettext("Vertical Acc. (RMS g)"),
    text_vertacc_subtitle = gettext("At 0.2+ g's Vertical Acc it is dangerous to perform tasks and potential seasickness"),
    text_vertacc_upgrade = gettext("Avoid rough conditions that can cause sea sickness, with Vertical Acceleration data"),
    text_vertical_accel = gettext("Vertical Acceleration"),
    text_wave_direction = gettext("Wave Direction"),
    text_wave_direction_unit = gettext("Wave Direction (%(unit)s)"),
    text_wave_height = gettext("Wave Height"),
    text_wave_height_unit = gettext("Wave Height (%(unit)s)"),
    text_wave_period = gettext("Wave Period"),
    text_wave_period_unit = gettext("Wave Period (%(unit)s)"),
    text_wind_unit = gettext("Wind (%(unit)s)"),
    text_wind_wave_professional = gettext("Wind Wave is only available with a Professional subscription");

export default class PWRoutingGraphs extends LitElement {
    static get properties() {
        return {
            routeList: {type: Array},
            formatting: {type: Array},
            waveFilter: {type: String}
        };
    }

    constructor() {
        super();
        this.routeList = [];
        this.formatting = [];
        this.waveFilter = "combined-wave";

        this.graphs = [];
    }

    getxAxisMax() {
        let xMax = 0;
        for (let i = 0; i < this.routeList.length; i++) {
            for (let j = 0; j < this.routeList[i].route.length; j++) {
                let timestamp = this.routeList[i].route[j].t;
                if (timestamp > xMax) {
                    xMax = timestamp;
                }
            }
        }
        return (new Date(xMax * 1000 + PWGMap.utcOffset * 3600000));
    }

    extractSeries(propName) {
        const angularPlots = ['twd', 'twa', 'cd', 'wd', 'mdww', 'mwd1', 'mwd2', 'mwd3'];
        let hasData = false;
        const series = $j.map(this.routeList, (o, index) => {
            let values = [];
            const all_values = [values],
                complete = o.complete;
            const utcOffset_ms = PWGMap.utcOffset * 3600000;
            for (let i = 0; i < complete.length; ++i) {
                //last entry for twa, cog, sog is nonsense but only twa graph shows
                if (propName === 'twa' && i === (complete.length - 1)) continue;
                const sample = getUserNode(complete[i]).converted;
                let value = null;
                if (propName in sample) {
                    value = sample[propName];
                    hasData = true;
                }
                if (typeof value === 'number' && isset(value, ROUTING_INVALID_VALUE)) {
                    if (values.length && angularPlots.indexOf(propName) !== -1) {
                        // Angle plots need to avoid ugly wraps...
                        const prevValue = values[values.length - 1][1];
                        if (Math.abs(value - prevValue) > 200) {
                            values = [];
                            all_values.push(values);
                        }
                    }
                }
                values.push([sample.time * 1000 + utcOffset_ms, value]);
            }
            return $j.map(all_values, values => {
                return {
                    data: values,
                    color: this.formatting[index].color
                };
            });
        });
        return {data: series, hasData};
    }

    get canShowCurrents() {
        // Normally true if user is professional AND currents setting is turned on
        return this.routeList.some(routeListItem => !!routeListItem.params?.showCurrents);
    }

    get canShowWaveEffects() {
        // Normally equivalent to user has professional
        return this.routeList.some(routeListItem => routeListItem.params?.showWaveEffects);
    }

    get canShowStwInWaves() {
        // Normally equivalent to user has standard or professional
        return this.routeList.some(routeListItem => routeListItem.params?.showStwInWaves);
    }

    get currentsEnabled() {
        // Reflects whether the user has turned on the currents option (they might NOT have professional)
        return this.routeList.some(routeListItem => routeListItem.params?.currentsEnabled);
    }

    get hasCurrents() {
        // Probably the same as canShowCurrents() unless the feature isn't working
        return this.routeList.some(routeListItem => routeListItem.complete?.some((node) => isset(node.cs)));
    }

    get hasWaveEffects() {
        // Probably the same as canShowWaveEffects but wave can be turned off
        return this.routeList.some(routeListItem => routeListItem.complete?.some((node) => isset(node.roll)));
    }

    get hasStwInWaves() {
        // Probably the same as canShowStnInWaves but the feature can be turned off I guess?
        return this.routeList.some(routeListItem => routeListItem.complete?.some((node) => isset(node.stwinwaves)));
    }

    addGraph(options, propName, title, yAxis, smartPhoneTitle, subtitle, enabled = true, upgradeText) {
        const showUpgradeText = upgradeText && !enabled;
        return this.addMultiGraph([{
            propName,
            showUpgradeText,
            upgradeText
        }], options, title, yAxis, smartPhoneTitle, subtitle, '');
    }

    addMultiGraph(propInfos, options, title, yAxis, smartPhoneTitle, subtitle, selectorHtml) {
        let seriesList = propInfos.map(prop => this.extractSeries(prop.propName, !prop.showUpgradeText));
        if (seriesList.some(series => series.hasData)) {
            this.graphs.push(
                [propInfos, options, seriesList]
            );
            return html`
              <div>
                <h3>${window.GLOBAL_isSmartPhone && smartPhoneTitle || title}</h3>
                ${selectorHtml ?? nothing}
                ${propInfos.map(prop => html`
                  <div style="position:relative">
                    <div id="${prop.propName}Graph"
                         class="routingGraph"
                         data-y-axis="${yAxis}"
                         style="${prop.showUpgradeText ? 'opacity:0.25' : ''}">
                      <div class="axes"></div>
                    </div>
                    ${subtitle ? html`<span class=subtitle>${subtitle}</span>` : nothing}
                    ${prop.showUpgradeText ? html`<pw-routing-upgrade-overlay class="routing-graph-upgrade-overlay" text="${prop.upgradeText}"></pw-routing-upgrade-overlay>` : ''}
                  </div>
                `)}
              </div>`;
        }
        return nothing;
    }

    async drawGraphs() {
        // Schedule graw drawing in a series of timeouts; hopefully the top graphs on the page will appear quickly.
        const graphs = this.graphs;
        while (!$j(this).is(':visible')) {
            // Put off drawing graphs
            await new Promise(resolve => setTimeout(resolve, 100));
            if (graphs !== this.graphs) {
                // Oops, a fresh update happened, don't complete this draw.
                return;
            }
        }
        // Now draw all graphs... unfortunately we must go ahead while we know the graph elements are visible.
        // Drawing asynchronously is tempting but it causes errors if the element we're drawing in disappears part way through.
        for (const [propInfos, options, seriesList] of graphs) {
            for (const [i, prop] of propInfos.entries()) {
                if (seriesList[i]) {
                    const
                        axes = this.renderRoot.querySelector(`#${prop.propName}Graph .axes`),
                        plot = $j.plot(axes, seriesList[i].data, options);
                    // Implement 'maxMin' option for yaxis; highest allowable start of y-axis
                    // (It might be a little cleaner to do this as a flot plugin.)
                    if (options.yaxis && "maxMin" in options.yaxis && plot.getAxes().yaxis.min > options.yaxis.maxMin) {
                        options.yaxis.min = options.yaxis.maxMin;
                        $j.plot(axes, seriesList[i].data, options);
                    }
                }
            }
        }
    }

    render() {
        // Note: this.addGraph(...) and this.multiGraph(...) return html but also enqueue plot parameters into this.graphs
        this.graphs = [];
        // this.drawGraphs() invokes flot functions to draw the graphs describes by this.graphs
        this.updateComplete.then(() => this.drawGraphs());
        const xMax = this.getxAxisMax(),
            options = {
                xaxis: {
                    mode: 'time',
                    timeformat: "%e<br/>%b</br>%H:%M",
                    reserveSpace: false, // this allows symbols to overflow, while making graphs uniform width
                    monthNames: window.GET_MONTH_NAMES_3(),
                    max: xMax
                }
            },
            twdOptions = {
                ...options,
                yaxis: {
                    min: 0,
                    max: 360,
                    tickSize: 45
                }
            },
            twaOptions = {
                ...options,
                yaxis: {
                    min: -180,
                    max: 180,
                    tickSize: 45,
                    tickFormatter: function formatter(val, axis) {
                        let string = val.toFixed(axis.tickDecimals);
                        if (string[0] === '-') { // port
                            string = '<span style="color:red">P</span>' + string.slice(1);
                        } else if (string !== '0') { // starboard
                            string = '<span style="color:green">S</span>' + string;
                        }
                        return string;
                    }
                }
            },
            slamOptions = {
                ...options,
                yaxis: {
                    min: 0,
                    max: 100,
                    tickSize: 10
                }
            },
            stwinwavesOptions = {
                ...options,
                yaxis: {
                    maxMin: 50,
                    max: 110,
                    tickSize: 10
                }
            },
            hasCurrentsData = this.hasCurrents,
            hasWaveEffectsData = this.hasWaveEffects,
            canShowWaveEffects = this.canShowWaveEffects,
            isProfessional = canShowWaveEffects, // Just defined to make currents graph code make more sense.
            showWaveEffectsUpgradeText = !hasWaveEffectsData && !canShowWaveEffects,
            waveSelectorHtml = html`<br>
            <pw-routing-waves-selector value="${this.waveFilter}">`,
            combinedWaveProp = {showUpgradeText: false, upgradeText: ''},
            windWaveProp = {
                showUpgradeText: showWaveEffectsUpgradeText,
                upgradeText: text_wind_wave_professional,
            },
            swellWave1Prop = {
                showUpgradeText: showWaveEffectsUpgradeText,
                upgradeText: text_primary_swell_professional,
            },
            swellWave2Prop = {
                showUpgradeText: showWaveEffectsUpgradeText,
                upgradeText: text_secondary_swell_professional,
            },
            swellWave3Prop = {
                showUpgradeText: showWaveEffectsUpgradeText,
                upgradeText: text_tertiary_swell_professional,
            };
        let waveHeightProps = [],
            waveDirectionProps = [],
            wavePeriodProps = [];
        switch (this.waveFilter) {
            case 'combined-wave':
                waveHeightProps = [{propName: 'wh', ...combinedWaveProp}];
                waveDirectionProps = [{propName: 'wd', ...combinedWaveProp}];
                wavePeriodProps = [{propName: 'wp', ...combinedWaveProp}];
                break;
            case 'wind-wave':
                waveHeightProps = [{propName: 'shww', ...windWaveProp}];
                waveDirectionProps = [{propName: 'mdww', ...windWaveProp}];
                wavePeriodProps = [{propName: 'mpww', ...windWaveProp}];
                break;
            case 'swell-1':
                waveHeightProps = [{propName: 'swh1', ...swellWave1Prop}];
                waveDirectionProps = [{propName: 'mwd1', ...swellWave1Prop}];
                wavePeriodProps = [{propName: 'mwp1', ...swellWave1Prop}];
                break;
            case 'swell-2':
                waveHeightProps = [{propName: 'swh2', ...swellWave2Prop}];
                waveDirectionProps = [{propName: 'mwd2', ...swellWave2Prop}];
                wavePeriodProps = [{propName: 'mwp2', ...swellWave2Prop}];
                break;
            case 'swell-3':
                waveHeightProps = [{propName: 'swh3', ...swellWave3Prop}];
                waveDirectionProps = [{propName: 'mwd3', ...swellWave3Prop}];
                wavePeriodProps = [{propName: 'mwp3', ...swellWave3Prop}];
                break;
            default:
                console.assert(false, "Unhandled wave filter case: " + this.waveFilter);
        }

        return [
            this.addGraph(options, "tws", text_tws, interpolateUnit(text_wind_unit, preference_units.wind_speed), interpolateUnit(text_tws_unit, preference_units.wind_speed)),
            this.addGraph(twdOptions, "twd", text_twd, interpolateUnit(text_direction_unit, preference_units.wind_direction)),
            $j(document.body).hasClass("DestinationForecast") ?
                nothing :
                this.addGraph(twaOptions, "twa", text_twa, text_direction_angle),

            this.addGraph(options, "rain", text_rain, interpolateUnit(text_rain_unit, preference_units.rainfall), interpolateUnit(text_rain_unit, preference_units.rainfall)),
            this.addGraph(options, "cape", text_CAPE, interpolateUnit(text_CAPE_unit, preference_units.cape), interpolateUnit(text_CAPE_unit, preference_units.cape)),
            this.addGraph(options, "temp", text_temperature, interpolateUnit(text_temperature_unit, preference_units.temperature), interpolateUnit(text_temperature_unit, preference_units.temperature)),
            this.addGraph(options, "press", text_pressure, interpolateUnit(text_pressure_unit, preference_units.pressure), interpolateUnit(text_pressure_unit, preference_units.pressure)),
            this.addMultiGraph(waveHeightProps, options, text_wave_height, interpolateUnit(text_height_unit, preference_units.wave_height), interpolateUnit(text_wave_height_unit, preference_units.wave_height), '', waveSelectorHtml),
            this.addMultiGraph(waveDirectionProps, twdOptions, text_wave_direction, interpolateUnit(text_direction_unit, preference_units.wave_direction), interpolateUnit(text_wave_direction_unit, preference_units.wave_direction), '', waveSelectorHtml),
            this.addMultiGraph(wavePeriodProps, options, text_wave_period, interpolateUnit(text_period_unit, preference_units.wave_period), interpolateUnit(text_wave_period_unit, preference_units.wave_period), '', waveSelectorHtml),

            this.currentsEnabled ? [
                this.addGraph(options, "cs", text_current_speed, interpolateUnit(text_speed_unit, preference_units.current_speed), interpolateUnit(text_current_speed_unit, preference_units.current_speed), '', isProfessional, text_know_with_current_speed_data),
                this.addGraph(twdOptions, "cd", text_current_direction, interpolateUnit(text_direction_unit, preference_units.current_direction), interpolateUnit(text_current_direction_unit, preference_units.current_direction), null, isProfessional, text_know_with_current_direction_data)
            ] : [],
            shouldShowFuelConsumption()
                ? [this.addGraph(options, 'accumfuelconsumed', text_fuel_consumption_label, interpolateUnit(text_fuel_consumption_unit, preference_units.fuel_volume), interpolateUnit(text_fuel_consumption_unit, preference_units.fuel_volume))]
                : [],
            this.hasStwInWaves ?
                this.addGraph(stwinwavesOptions, "stwinwaves", text_influence_of_waves, text_influence_of_waves_percent, text_influence_of_waves_percent, null)
                : nothing,
            hasWaveEffectsData || !canShowWaveEffects ? [
                this.addGraph(options, "roll", text_roll, text_roll_rms_degrees, text_roll_degrees, text_roll_subtitle, hasWaveEffectsData, text_roll_upgrade),
                this.addGraph(options, "vertacc", text_vertical_accel, text_vertacc_rms_g, text_vertacc, text_vertacc_subtitle, hasWaveEffectsData, text_vertacc_upgrade),
                this.addGraph(slamOptions, "slaminc", text_slamming_incidence, text_slamming_inc_percent, text_slaminc_percent, text_slaminc_subtitle, hasWaveEffectsData, text_slaminc_upgrade)
            ] : []
        ];
    }

    static get styles() {
        // language=css
        const styles = css`
            :host {
                display: block;
            }

            h3 {
                margin: 3em 0 0 0;
            }

            .subtitle {
                font-weight: 500;
                font-size: 12px;
                display: inline-block;
                margin-top: 15px;
            }

            .routingGraph {
                max-width: 5.72in;
                margin: 0 auto;
            }

            .routingGraph > .axes {
                height: 4.165in;
            }

            .routingGraph:before {
                content: attr(data-y-axis);
                display: block;
                -webkit-transform: rotate(270deg);
                -webkit-transform-origin: left top;
                -ms-transform: rotate(270deg);
                -ms-transform-origin: left top;
                height: 0.3125in;
                width: 4.165in;
                position: relative;
                top: 4.165in;
                left: -0.3125in;
            }
        `;
        // FIXME use a media query? This replicates what the CSS files did before refactor so it's okay for the moment.
        if (isSmartPhone) {
            return [
                styles,
                // language=css
                css`
                    .routingGraph {
                        margin: 0 0 0 10vw;
                    }

                    .routingGraph > .axes {
                        height: 77vw;
                    }

                    .routingGraph:before {
                        height: 10vw;
                        width: 77vw;
                        top: 77vw;
                        left: -7vw;
                    }
                `
            ];
        }
        return styles;
    }
}